.contact {
    padding: 2rem 0 4rem 0;
    // &__container {
    //     padding: 0 4rem;
    // }
    &__form {
        padding: 2rem 0;
    }
    &__title {
        color: $primary-color;
        text-align: center;
    }
    &__content {
        text-align: center;
        margin: 2rem 0;
    }
}