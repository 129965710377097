.footer {
    padding: 3rem 0 2.5rem 0;
    &__content {
        display: flex;
        flex-wrap:wrap;
    }
    &__row {
        width: 98%;
        margin-right: 2%;
        @include iphone {
            width: 48%;
            margin-right: 2%;
        }
        @include tablet {
            width: 31.333%;
            margin-right: 2%;
        }
        @include laptop {
            width: 28%;
            margin-right: 2%;
        }
    }
    &__row:nth-child(4) {
        @include iphone {
            width: 48%;
            margin-right: 2%;
            text-align:left;
        }
        @include tablet {
            width: 100%;
            margin-right: 0;
        }
        @include laptop {
            width: 10%;
            margin-right: 0;
            text-align:right;
        }
    }
    &__title {
        font-size: 1.05rem;
        text-transform: uppercase;
        color: $primary-color;
    }
    &__content {
        font-size: 0.89rem;
        line-height: 1.7rem;
        margin: 0.9rem 0 0.5rem 0;
        @include iphone {
            margin: 0.9rem 0 3rem 0;
        }
    }
    &__ul {
        list-style-type: none;
        padding-left: 0;
    }
    &__li {
        font-size: 0.89rem;
        line-height: 1.7rem;
    }
    &__link {
        color: #667788;
    }
    &__baseline {
        padding: 2.2rem 0;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &__baseline-link {
        color: #667788;
        font-size: 0.8rem;
        margin: 0;
        line-height: 1.1rem;
        width: 100%;
        margin-bottom: 0.3rem;
        @include tablet {
            width: 50%;
            margin-bottom: 0;
        }
    }
    &__baseline-link:nth-child(2) {
        margin-left: auto;
        margin-right: 0;
        text-align: left;
        @include tablet {
            text-align: right;
        }
    }
    &__social-link {
        display: inline-block;
        width: 25px;
        height: auto;
        margin: 1.4rem 12px 1rem 0;
        @include tablet {
            margin: 1.4rem 12px 0 0;
        }
        @include laptop {
            margin: 1.4rem 0 1rem 12px;
        }
    }
    &__social-icon {
        display: inline-block;
        width: 100%;
        height: auto;
        opacity: 0.9;
    }
}