.gdpr-banner {
    position: fixed; 
    bottom: 0; 
    width: 100%; 
    background-color: darken($tertiary-color, 20%); 
    color: #fff; 
    padding: 20px; 
    text-align: center; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    z-index: 9999;
    opacity:0.95;
    &__content {
        font-size: 0.9rem;
        margin: 0;
        padding: 0 10px;
        color: #8f9aa2;
    }
    &__link {
        color: #8f9aa2;
    }
}