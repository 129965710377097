.landing-page {
    background: black;
    &::before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        z-index: 0;
    }
}