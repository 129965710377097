.about-intro {
    background: $white;
    padding: 2.5rem 0 0.5rem 0;
    &__container {
        display: flex;
        flex-wrap:wrap;
        position: relative;
    }
    &__row:nth-child(1) {
        width: 100%;
        margin-right: 0%;
        margin-top: 0;
    }
    &__heading {
        display: inline-block;
        color: $primary-color;
        letter-spacing: 0.09rem;
        margin: 0.7rem 0;
        width: 100%;
        @include desktop {
            width: 40%;
        }
    }
    &__heading-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    &__abb {
        font-size: 66%;
    }
    &__contact {
        font-family: $body-font-family;
        width: 100%;
        margin-bottom: 0.8rem;
        margin-top: 0.45rem;
        @include desktop {
            width: 60%;
            margin-bottom: 0;
            margin-top: 0;
        }
        justify-content: flex-start;
        display: flex;
        flex-wrap:wrap;
        margin-left: auto;
        align-items: center;
    }
    &__subheading {
        font-family: $heading-font-family;
        color: $secondary-color;
        font-size: 1.38rem;
        padding: 0;
        margin: 1.35rem 0;
        font-weight: 500;
    }
    &__content {
        margin: 2rem 0;
        line-height: 2rem;
    }
    &__content:nth-child(2) {
        font-weight:700 !important;
    }
    &__underline {
        color:transparent;
        border-top: 1px solid #ccc;
        @include iphone {
            border-top: 0.2px solid #eee;
        }
    }
    &__tel {
        font-size: 1rem;
        width: 100%;
        margin-bottom: 0.5rem;
        @include iphone {
            width: auto;
            font-size: 0.9rem;
            margin-bottom: 0;
        }
        @include desktop {
            font-size: 0.97rem;
        }
        text-decoration: none;
        color: $secondary-color;
        padding-left: 0%;
    }
    &__email {
        font-size: 1rem;
        width: 100%;
        padding-left: 0%;
        @include iphone {
            width: 280px;
            padding-left: 7%;
            font-size: 0.9rem;
        }
        @include desktop {
            font-size: 0.97rem;
        }
        text-decoration: none;
        color: $secondary-color;
    }
    &__btn {
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: white;
        padding: 0.8rem 2rem;
        margin: 0.3rem 0;
        transition: background 0.15s ease-in;
    }
    &__btn:hover {
        background: $secondary-color;
    }
    &__img {
        width: 100%;
        transform: rotate(0deg);
        margin: 2rem 0rem;
        border: 0px solid $white;
        @include iphone {
            width: 230px;
            transform: rotate(5deg);
            margin: 2.5rem;
            border: 7px solid $white;
        }
        @include iphone {
            width: 260px;
        }
        position: relative;
        top: unset;
        right: unset;
        float: right;
        height: auto;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    }
}