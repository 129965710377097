// Hamburger Button
.hamburger {
    background-color: transparent; 
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    overflow: visible; 
    padding: 0;
    margin-left: auto;
    margin-right: 0;
    opacity: 0.8;
    display:block;
    @include laptop {
        display:none;
    }
  }
  
  .hamburger-box {
    height: 24px;
    display: inline-block;
    position: relative;
    width: 33px;
  }
  
  .hamburger-inner {
    display: block;
    margin: -2px;
    position: relative;
    top: 50%;
    
    &,
    &:before,
    &:after {
      background-color: #fff;
      border-radius: 4px;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      width: 33px;
      
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    
    &:before {
      top: -10px;
    }
    
    &:after {
      bottom: -10px;
    }
  }
  
  /* No animation */
  .hamburger--boring .hamburger-inner, 
  .hamburger--boring .hamburger-inner:before,
  .hamburger--boring .hamburger-inner:after {
    transition-property: none;
  }
  
  .hamburger--boring.is-active {
    
      .hamburger-inner {
        transform: rotate(45deg);
  
        &:before {
          top: 0;
          opacity: 0;
        }
  
        &:after {
          bottom: 0;
          transform: rotate(-90deg);
        }
    }
  }
  
  /* Arrow */
  .hamburger--arrow.is-active {
    
      .hamburger-inner {
  
        &:before {
          transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1) ;
        }
  
        &:after {
          transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }
  
  /* Squeeze */
  .hamburger--squeeze {
    
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      
      &:before {
        transition: top 0.1s 0.14s ease, opacity 0.1s ease;
      }
      
      &:after {
        transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }
  
  .hamburger--squeeze.is-active {
    
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      
      &:before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }
      
      &:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      
    }
    
  }
  