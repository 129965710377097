.childrens-university {
    background: #efefef;
    padding: 2rem 0;
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background: $white;
        padding: 1rem 1rem;
        @include laptop {
            padding: 1rem 1.5rem;
        }
        border-radius: 0.75rem;
    }
    &__logo-link {
        width: 210px;
        @include tablet {
            width: 120px;
        }
        @include laptop {
            width: 210px;
        }
        @include desktop {
            width: 250px;
        }
        height: auto;
    }
    &__logo {
        width: 100%;
        height: auto;
    }
    &__content {
        width: 100%;
        font-size: 1.05rem;
        line-height: 1.55rem;
        margin: 0.5rem 0;
        text-align: center;
        @include tablet {
            width: calc(83% - 212px);
            font-size: 1rem;
            line-height: 1.45rem;
            margin: 0;
            text-align:left;
        }
        @include laptop {
            width: calc(89% - 362px);
            font-size: 1rem;
            line-height: 1.45rem;
        }
        @include desktop-small {
            width: calc(83% - 322px);
            font-size: 1.05rem;
            line-height: 1.55rem;
        }
        @include desktop {
            width: calc(86% - 362px);
        }
        font-family: $heading-font-family;
        font-style: italic;
        color: $secondary-color;
        padding: 0 4.5%;
    }
    &__btn {
        width: 100%;
        font-size: 0.98rem;
        margin: 0.5rem 0 0.3rem 0;
        @include tablet {
            width: 140px;
            font-size: 0.87rem;
            margin: 0.3rem 0;
        }
        @include laptop {
            width: 152px;
            font-size: 0.98rem;
        }
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: $white;
        padding: 0.5rem 0;
        transition: background 0.15s ease-in;
        text-align: center;
    }
    &__btn:hover {
        background: $secondary-color;
    }
}