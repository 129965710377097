.our-mission {
    position: relative;
    background: $primary-color;
    padding: 3rem 0 3rem 0;
    background-image: url("../images/parallax-background-new.jpg");
    background-repeat: no-repeat;
    background-size: 300%;
    @include iphone {
        background-size: 220%;
    }
    @include tablet {
        background-size: 160%;
    }
    @include desktop-small {
        background-size: 130%;
    }
    @include desktop {
        background-size: cover;
    }
    &__container {
        margin: 0;
    }
    &__container::before {
        content: "";
        background: #124a8a;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.75;
        z-index: 0;
    }
    &__heading {
        position: relative;
        color: $primary-color;
        z-index: 1;
        margin-top: 0.6rem;
        font-size: 1.65rem;
        @include laptop {
            font-size: 1.85rem;
        }
    }
    &__card {
        background: $white;
        width: 97.5%;
        padding: 4% 7% 4% 5.5%;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        text-align:center;
        min-height: 445px;
        @include iphone {
            text-align: left;
            min-height: auto;
            width: 92%;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            padding: 4% 4% 4% 4%;
        }
        @include tablet {
            min-height: 375px;
            width: 42%;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            padding: 4% 4% 4% 4%;
        }
        @include laptop {
            width: 37.5%;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            min-height: auto;
            padding: 4% 7% 4% 5.5%;
        }
    }
    &__content {
        font-size: 0.85rem;
        line-height: 1.8rem;
        @include iphone {
            font-size: 0.9rem;
            line-height: 1.9rem;
        }
        @include tablet {
            font-size: 0.9rem;
            line-height: 1.8rem;
        }
        @include laptop {
            font-size: 0.9rem;
            line-height: 1.8rem;
        }
        @include desktop-small {
            font-size: 1rem;
            line-height: 2.0rem;
        }
    }
    &__img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        height: 350px;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        display: none !important;
        @include tablet {
            display: block !important;
            height: 535px;
            width: 50%;
            max-width: 50%;
        }
        @include desktop-small {
            display: block !important;
            height: 565px;
        }
        @include desktop {
            height: 510px;
        }
    }
    &__item {
        display: flex !important;
        flex-wrap: wrap !important;
        border-radius: 1rem;
        height: 100%;
    }
    & .item {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    &__underline {
        margin-left:auto;
        @include iphone {
            margin-left:0;
        }
        width: 202px;
    }
    &__btn {
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: $white;
        padding: 0.65rem 1.5rem;
        margin: 0.3rem 0;
        font-size: 0.9rem;
        transition: background 0.15s ease-in;
    }
    &__btn:hover {
        background: $secondary-color;
    }
}