.page-not-found {
    padding: 2rem 0 4rem 0;
    // &__container {
    //     padding: 0 4rem;
    // }
    &__title {
        color: $primary-color;
        text-align: center;
    }
    &__content {
        text-align: center;
        margin: 2rem 0;
    }
    &__menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    &__menu-list {
        padding: 0.4rem 0.7rem;
        display: inline;
    }
    &__menu-link {
        //color: $white;
        //background: $primary-color;
        font-size: 0.9rem;
        text-decoration: underline;
    }
}