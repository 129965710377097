.success-chart {
    width: 100%;
    margin-bottom: 3rem;
    &__container {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(1, 1fr);
        @include desktop-small {
            grid-template-columns: repeat(2, 1fr);
        }
        align-items: start;
    }
    &__table {
        width: 100%;
        border: 1px solid lighten($primary-color, 20%);
        border-collapse: collapse;
        height: auto;
    }
    &__table tr td {
        border-bottom: 1px solid $primary-color;
        background: lighten($primary-color, 60.5%);
        padding: 0.5rem;
        font-size: 0.85rem;
        opacity: 0.85;
        @include iphone {
            font-size: 0.9rem;
        }
        @include laptop {
            font-size: 0.95rem;
        }
    }
    &__title {
        font-family: $body-font-family;
        font-size:1.1rem;
        font-weight: 500;
        color: $white;
        background: $primary-color;
        padding: 0.7rem 2.5rem;
        margin: 0;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        display: inline-block;
    }
    &__row:nth-child(2) {
        margin-top: 1.5rem;
        @include desktop-small {
            margin-top: 0;
        }
    }
}