.our-rates {
    background: #efefef;
    padding: 2rem 0;
    margin-top: 2.5rem;
    margin-bottom: -2.9rem;
    &__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background: $white;
        padding: 1rem 1rem;
        @include laptop {
            padding: 1.25rem 1.2rem 0.8rem 0.8rem;
        }
        border-radius: 0.75rem;
    }
    &__logo-link {
        width: 220px;
        display: none;
        @include tablet {
            width: 200px;
            display: block;
        }
        @include laptop {
            width: 265px;
        }
        @include desktop {
            width: 260px;
        }
        height: auto;
    }
    &__logo {
        width: 100%;
        height: auto;
    }
    &__content {
        width: 100%;
        font-size: 1.05rem;
        line-height: 1.55rem;
        margin: 0.5rem 0;
        text-align: center;
        @include tablet {
            width: calc(83% - 132px);
            font-size: 1rem;
            line-height: 1.45rem;
            margin: 0;
            text-align:left;
        }
        @include laptop {
            width: calc(89% - 235px);
            font-size: 1rem;
            line-height: 1.45rem;
        }
        @include desktop-small {
            width: calc(83% - 322px);
            font-size: 1.05rem;
            line-height: 1.55rem;
        }
        @include desktop {
            width: calc(86% - 335px);
        }
        font-family: $heading-font-family;
        font-style: italic;
        color: $secondary-color;
        padding: 0 3%;
    }
    &__content-inner {
        width: 100%;
        height: auto;
        color: $secondary-color;
        font-size: 1rem;
        line-height: 1.65rem;
        @include tablet {
            font-size: 0.85rem;
            line-height: 1.30rem;
        }
        @include laptop{
            font-size: 1rem;
            line-height: 1.45rem;
        }
        @include desktop {
            line-height: 1.6rem;
        }
        // @include desktop-small {
        //     line-height: 1.6rem;
        // }
        // @include desktop {
        //     line-height: 2rem;
        // }
        margin: 1rem 0;
    }
    &__content-heading {
        font-family: $heading-font-family;
        color: $primary-color;
        font-style: normal;
        text-transform: uppercase;
        font-size: 1.05rem;
        @include laptop {
            font-size: 1.2rem;
        }
        @include desktop {
            font-size: 1.5rem;
        }
        margin: 0.5rem 0 0 0;
    }
    &__btn {
        width: 100%;
        font-size: 0.98rem;
        margin: 0.5rem 0 0.3rem 0;
        @include tablet {
            width: 100%;
            font-size: 0.87rem;
            margin: 0.5rem 0.6rem 0.3rem 0.4rem;
        }
        @include laptop {
            width: 100%;
            font-size: 0.98rem;
            margin: 0.5rem 0.6rem 0.3rem 0.4rem;
        }
        @include desktop-small {
            width: 152px;
            font-size: 0.98rem;
            margin: 0.5rem 0 0.3rem 0;
        }
        @include desktop {
            width: 152px;
            font-size: 0.98rem;
        }
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: $white;
        padding: 0.5rem 0;
        transition: background 0.15s ease-in;
        text-align: center;
    }
    &__btn:hover {
        background: $secondary-color;
    }
    &__flash {
        background: $primary-color;
        color: $white;
        position: absolute;
        top: -166px;
        left: -98px;
        text-align: center;
        padding: 2.1rem 1rem 0.9rem 1rem;
        width: 220px;
        transform: rotate(-45deg);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.7rem;
    }
}