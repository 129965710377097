.about-us-intro {
    margin-top: 3rem;
    &__container {
        background: $midpalegrey;
    }
    &__content {
        margin: 0;
        padding: 1.8rem 2rem 2rem 2rem;
    }
    &__content:nth-child(1) {
        font-weight: 700;
        color: $primary-color;
        padding-bottom: 0;
    }
}