.owl-theme .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 47%;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    opacity: 0.8;
    left: 0.5rem;
    display: none;
    @include iphone {
        display: block;
    }
    @include laptop {
        left: 1rem;
        width: 50px;
    }
    @include desktop-small {
        left: 3rem;
        width: 50px;
    }
    @include desktop {
        left: 5.5rem;
    }
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    right: 0.5rem;
    opacity: 0.8;
    display: none;
    @include iphone {
        display: block;
    }
    @include laptop {
        right: 1rem;
        width: 50px;
    }
    @include desktop-small { 
        right: 3rem;
        width: 50px;
    }
    @include desktop { 
        right: 5.5rem;
    }
}

.owl-testimonial .owl-nav button.owl-next {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    right: -3.5rem;
    @include laptop {
        width: 50px;
        right: -5rem;
    }
    opacity: 0.6;
}
.owl-testimonial .owl-nav button.owl-prev {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    opacity: 0.6;
    left: -3.5rem;
    @include laptop {
        width: 50px;
        left: -5rem;
    }
}

.owl-mission .owl-nav button.owl-next {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    right: -3.5rem;
    @include laptop {
        width: 50px;
        right: -5rem;
    }
    opacity: 0.6;
}
.owl-mission .owl-nav button.owl-next:hover {
    background: transparent;
}
.owl-mission .owl-nav button.owl-prev {
    position: absolute;
    color: white;
    font-size: 4rem;
    width: 50px;
    opacity: 0.6;
    left: -3.5rem;
    @include laptop {
        width: 50px;
        left: -5rem;
    }
}
.owl-mission .owl-nav button.owl-prev:hover {
    background: transparent;
}
.owl-carousel .owl-item img {
    display: block;
    width: auto;
}
.owl-carousel .owl-nav.disabled {
    display: block;
}
.testimonials .owl-theme .owl-nav {
    top: 33%;
    display: none;
    @include tablet {
        display: block;
    }
}
.owl-mission .owl-nav {
    top: 42%;
    display: none;
    @include tablet {
        display: block;
    }
}
.owl-mission .owl-stage {
    display: flex;
}
.owl-mission .owl-item {
    display: flex;
}
.owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent !important;
}