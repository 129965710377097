.mt-0 {
    margin-top: 0rem;
}
.mt-1 {
    margin-top: 1rem;
}
.mt-1-5 {
    margin-top: 1.5rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mt-2-5 {
    margin-top: 2.5rem;
}
.mt-3 {
    margin-top: 3rem;
}
.mt-3-5 {
    margin-top: 3.5rem;
}
.mt-4 {
    margin-top: 4rem;
}
.mb-0 {
    margin-bottom: 0rem;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-1-5 {
    margin-bottom: 1.5rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-2-5 {
    margin-bottom: 2.5rem;
}
.mb-3 {
    margin-bottom: 3rem;
}
.mb-3-5 {
    margin-bottom: 3.5rem;
}
.mb-4 {
    margin-bottom: 4rem;
}