/** 
 * Shame.
 *
 * Specificity overrides and poor code.
 */

// Example:

// Needed to use to target paragraphs generated by a rich text editor.
//  .section p {
//     color: red !important;
//  }

body {
  margin-top: 0;
}
