.our-courses {
    padding: 2.5rem 0 4.5rem 0;
    background: $white;
    &__title {
        display: inline-block;
        color: $primary-color;
        letter-spacing: 0.09rem;
        margin: 0.7rem 0 0.9rem 0;
    }
    &__heading {
        display: inline-block;
        color: $primary-color;
        letter-spacing: 0.09rem;
        margin: 0.7rem 0 0 0;
        font-size: 1.23rem;
        @include tablet {
            font-size: 1.13rem;
        }
        @include laptop {
            font-size: 1.23rem;
        }
        @include desktop {
            font-size: 1.33rem;
        }
    }
    &__content {
        padding: 0 1rem;
        margin: 1.6rem 0 2.7rem 0;
    }
    &__item-content {
        opacity:0.8;
        font-size: 0.95rem;
        padding: 0 1rem;
        margin: 0.8rem 0 1rem 0;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // grid-gap: 1rem;;
        // grid-template-columns: repeat(1, 1fr);
        // @include iphone {
        //     grid-template-columns: repeat(2, 1fr);
        // }
        // @include tablet {
        //     grid-template-columns: repeat(2, 1fr);
        // }
        // @include laptop {
        //     grid-template-columns: repeat(3, 1fr);
        // }
        // @include desktop-small {
        //     grid-template-columns: repeat(3, 1fr);
        // }
        // @include desktop {
        //     grid-template-columns: repeat(4, 1fr);
        // }
    }
    &__item {
        // border: 1px solid lighten($primary-color, 60%);
        // background: #f7f7f7;
        position: relative;
        border: 1px solid #dfe7ef;
        background: #f2f2f2;
        border-radius: 1rem;
        padding: 1rem 1% 1.7rem 1%;
        width: 96%;
        margin: 0 0.75% 1rem 0.75%;
        height: auto;
        @include iphone {
            padding: 1rem 1% 1.7rem 1%;
            width: 46%;
            margin: 0 0.75% 1rem 0.75%;
            height: 260px;
        }
        @include tablet {
            padding: 1rem 1% 1.7rem 1%;
            width: 29.333%;
            margin: 0 0.75% 1rem 0.75%;
            height: 265px;
        }
        @include desktop {
            padding: 1rem 1% 1.7rem 1%;
            width: 21%;
            margin: 0 0.75% 1rem 0.75%;
            height: 248px;
        }
    }
    &__item:nth-child(15) &__heading {
        letter-spacing: 0;
    }
    &__item.active {
        box-shadow: 0px 0px 12px rgba(2, 38, 79, 0.2);
    }
    &__item.active &__btn::after {
        content: "";
        //background: rgba(2, 38, 79, 0.04);
        //background: rgba(27, 86, 153, 0.04);
        background: rgba(55, 55, 55, 0.02);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1rem;
        border: 1px solid #c4ccd4;
    }
    &__icon {
        width: 84px;
        height: auto;
        display: block;
        margin-left:auto;
        margin-right: auto;
    }
    &__btn {
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: white;
        padding: 0.65rem 1.5rem;
        margin: 0.3rem 0;
        font-size: 0.88rem;
        transition: background 0.15s ease-in;
        line-height: 0.9rem;
    }
    &__btn:hover {
        //background: $secondary-color;
        background: #0a376b;
    }
    &__btn::after {
        content: "";
        //background: rgba(2, 38, 79, 0.05);
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1rem;
    }
}