.colleges {
    position: relative;
    background: $primary-color;
    padding: 3.6rem 0 3.6rem 0;
    background-image: url("../images/parallax-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    &__container {
        display: flex;
        flex-wrap: wrap;
    }
    &__container::before {
        content: "";
        background: #124a8a;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.75;
        z-index: 0;
    }
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        width: 100%;
        z-index: 1;
        padding: 0 0%;
        border-left: 0px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 3.5rem;
        @include laptop {
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            width: 41.5%;
            padding: 0 4%;
            margin-bottom: 0;
        }

    }
    &__card:nth-child(1) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 3rem;
        @include laptop {
            border-bottom: 0px solid rgba(255, 255, 255, 0.2);
            padding-bottom: 0;
        }
    }
    &__card:nth-child(2) {
        border-right: 0px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 0.6rem;
        @include laptop {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            margin-bottom: 0;
        }
    }
    &__heading {
        color: $white;
        text-transform: uppercase;
        font-size: 1.2rem;
        @include laptop {
            font-size: 1.15rem;
        }
        @include desktop-small {
            font-size: 1.35rem;
        }
        margin-top: 0;
    }
    &__content {
        color: $white;
        color: rgba(255, 255, 255, 0.8);
        font-size: 1rem;
        margin: 0.5rem 0;
        @include laptop {
            font-size: 0.89rem;
        }
        @include desktop_small {
            font-size: 0.9rem;
        }
    }
    &__btn {
        position: relative;
        display: inline-block;
        background: $white;
        border-radius: 1.8rem;
        color: $primary-color;
        padding: 0.5rem 1.6rem;
        margin: 0.3rem 0;
        z-index: 1;
        text-decoration: none;
        margin-top: 2rem;
        font-size: 0.95rem;
        font-weight: 500;
        transition: background 0.15s ease-in;
    }
    &__btn:hover {
        background: lighten($secondary-color, 25%);
    }
    &__logo-1 {
        margin-right: 0;
        margin-left: auto;
        height: 48px;
        position: absolute;
        right:0;
        bottom: 0;
    }
    &__logo-2 {
        margin-right: 0;
        margin-left: auto;
        height: 60px;
        position: absolute;
        right:0;
        bottom: 0;
    }
    &__cta {
        display: flex;
        position: relative;
        margin-bottom: 0;
        margin-top: auto;
    }
}