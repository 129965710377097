$mobile-width: 90%;
$tablet-width: 85%;
$laptop-width: 75%;
$full-width: 95%;

$max-width: 1270px;

$extra-small-max-width: 750px;
$small-max-width: 1000px;
$large-max-width: 1500px;
$extra-large-max-width: 1750px;

.container {
  width: $mobile-width;
  max-width: $max-width;
  margin: auto;

  @include tablet {
    width: $tablet-width;
  }

  @include laptop {
    width: $laptop-width;
  }

  &--extra-small {
    max-width: $extra-small-max-width;
  }

  &--small {
    max-width: $small-max-width;
  }

  &--large {
    max-width: $large-max-width;
  }

  &--extra-large {
    max-width: $extra-large-max-width;
  }

  &--full-width {
    width: $full-width;
    max-width: unset;
  }
}

.container-underline {
  color: transparent;
  border-top: 0.2px solid #eee;
  margin: 2rem 0;
}

// .container {
//   max-width: 1360px;
//   margin-left: auto;
//   margin-right: auto;
// }
