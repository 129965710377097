.mission-statement {
    background: $primary-color;
    padding: 1.6rem 1.1rem 1.9rem 1.1rem;
    @include tablet {
        padding: 1.6rem 2.8rem;
    }
    @include laptop {
        padding: 2rem 0.5rem;
    }
    color: rgba(255, 255, 255, 0.77);
    text-align: center;
    font-style:italic;
    font-family: $heading-font-family;
    font-weight: 400;
    &__container {
        @include tablet {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        
          @include laptop {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
          }
    }
    &__heading {
        padding: 0;
        font-size: 1.05rem;
        line-height: 1.5rem;
        transform: skewX(0deg);
        @include iphone {
            transform: skewX(-10deg);
            font-size: 1.15rem;
            line-height: 1.6rem;
        }
        @include tablet {
            font-size: 1.29rem;
            line-height: 1.8rem;
        }
        @include laptop {
            font-size: 1.52rem;
            line-height: 2rem;
        }
        letter-spacing: 0.05rem;
        margin: 0.14rem 0;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 400;
    }
}