.four-column-featured {
    &__container {
        display: grid;
        grid-gap: 0.55rem;
        grid-template-columns: repeat(1, 1fr);
        @include iphone {
            grid-template-columns: repeat(2, 1fr);
        }
        @include laptop {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    &__icon {
        width: 70px;
        height: auto;
    }
    &__card {
        background: $palegrey;
        width: auto;
        margin-right: 0;
        text-align: center;
        padding: 1.5rem 0.9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
    }
    &__card:nth-child(1) &__btn {
        margin-top: 1.5rem;
    }
    &__heading {
        color: $primary-color;
        margin: 0.6rem 0.5rem 0 0.5rem;
        font-size: 1.3rem;
    }
    &__content {
        font-size: 0.87rem;
    }
    &__btn {
        font-size: 0.8rem;
        position: relative;
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: $white;
        padding: 0.8rem 2rem;
        margin: 0.3rem 0;
        z-index: 1;
        text-decoration: none;
        margin-top: 1.5rem;
        @include iphone {
            margin-top: auto;
        }
        transition: background 0.15s ease-in;
    }
    &__btn:hover {
        background: lighten($secondary-color, 25%);
    }
}