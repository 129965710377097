.course {
    background: lighten($midpalegrey, 5%);
    margin: 0;
    position: relative;
    top: 0;
    padding: 2rem 0 4rem 0;
    &__heading {
        color: $primary-color;
        text-transform: capitalize;
    }
    &__title {
        color: $primary-color;
        font-size: 1.47rem;
        margin-top: 0;
    }
    &__content-top {
        display: flex;
        flex-wrap: wrap;
    }
    &__content {
        background: $white;
        border: 1px solid darken($midpalegrey, 5%);
        padding: 2rem 1.65rem 2rem 1.65rem;
        @include iphone {
            padding: 2rem 2rem 2rem 2rem;
        }
        @include tablet {
            padding: 2.8rem 2.2rem 2rem 2.2rem;
        }
        @include laptop {
            padding: 3rem 2.8rem 2rem 2.8rem;
        }
    }
    &__row:nth-child(1) {
        width: 100%;
        margin-right: 0;
        @include tablet {
            width: 65%;
            margin-right: 4%;
        }
        height: auto;
    }
    &__row:nth-child(2) {
        width: 100%;
        height: auto;
        @include tablet {
            width: 31%;
            height: auto;
        }
    }
    &__img {
        width: 100%;
        height: auto;
    }
    &__img:nth-child(1) {
        margin-bottom: 1rem;
    }
    &__tab {
        display: inline-block;
        background: white;
        padding: 1rem 1.65rem;
        @include iphone {
            padding: 1rem 2rem;
        }
        @include tablet {
            padding: 1rem 2rem;
        }
        @include laptop {
            padding: 1rem 2.7rem;
        }
        border-top-left-radius: 0.7rem;
        border-top-right-radius: 0.7rem;
        border-top: 1px solid darken($midpalegrey, 5%);
        border-left: 1px solid darken($midpalegrey, 5%);
        border-right: 1px solid darken($midpalegrey, 5%);
        margin-bottom: -2px;
    }
    &__tab-title {
        color: $primary-color;
        display: inline-block;
        background: white;
        margin: 0;
        width: 100%;
        font-size: 1.2rem;
    }
    &__tab-icon {
        color: $tertiary-color;
    }
    &__lists {
        padding-left: 1.2rem;
    }
    &__list {
        color: $tertiary-color;
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
    }
    &__form-row-left {
        width: 100%;
        margin-right: 0%;
        @include tablet {
            width: 49%;
            margin-right: 1%;
        }
    }
    &__form-row-right {
        width: 100%;
        margin-left: 0%;
        @include tablet {
            width: 49%;
            margin-left: 1%;
        }
    }
    &__form-row-fw {
        width: 100%;
        height: auto;
        margin-top: 1rem;
    }
    &__form-row-wide {
        width: 100%;
        height: auto;
    }
    &__form-row label {
        color: $primary-color;
        font-weight: $bold;
        font-size: 0.9rem;
    }
    &__form-row-fw label {
        color: $primary-color;
        font-weight: $bold;
        font-size: 0.9rem;
    }
    &__form-label-ability {
        display: block;
        color: $primary-color;
        font-weight: 700;
        font-size: 0.9rem;
        margin: 0.5rem 0;
    }
    &__form-submit-error {
        font-size: 0.89rem;
        color: red;
        font-weight: $normal;
        font-style:italic;
        margin: 0 4px;
    }
    &__form-input{
        font-family: $body-font-family;
        font-size:0.95rem;
        width: 97%;
        padding: 0.8rem 1.5%;
        background: lighten($midpalegrey, 5%);
        border: 1px solid darken($midpalegrey, 5%);
        border-radius:0.3rem;
        margin-bottom: 1rem;
        margin-top: 0.25rem;
    }
    &__form-select {
        font-size:0.95rem;
        color: $secondary-color;
        padding: 0.7rem;
        background: lighten($midpalegrey, 5%);
        border: 1px solid darken($midpalegrey, 5%);
        display: block;
        margin-top: 0.3rem;
        margin-bottom: 2rem;
        width: 100%;
    }
    &__form-radio {
        margin: 0 7px 0 0;
    }
    &__form-label {
        color: $tertiary-color;
        margin-right: 0.7rem;
    }
    &__form-textarea {
        font-family: $body-font-family;
        font-size:0.95rem;
        width:97%;
        border: 1px solid darken($midpalegrey, 5%);
        padding: 0.8rem 1.5%;
        background: lighten($midpalegrey, 5%);
        border-radius:0.3rem;
        color: $tertiary-color;
        margin-top: 0.25rem;
        height: 115px;
    }
    &__form-submit {
        cursor: pointer;
        display: block;
        font-size: 0.9rem;
        letter-spacing:0.02rem;
        background: $primary-color;
        color: $white;
        padding: 0.75rem 1.5rem;
        margin-top:2rem;
        border: 0;
        border-radius: 1.5rem;
        text-transform: uppercase;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        transition: background 0.15s ease-in;
    }
    &__form-submit:hover {
        background: $secondary-color;
    }
    &__form-radio-wrap {
        display: block;
        @include desktop-small {
            display: inline;
        }
    }
    &__form-info {
        text-align:center; 
        color: white; 
        padding:0.7rem 0;
    }
    &__form-hr {
        width: 100%;
        opacity: 0.2;
        margin: 1.5rem 0;
    }
    &__form-hr-2 {
        width: 100%;
        opacity: 0.2;
        margin: 1.5rem 0 0 0;
    }
}
input:focus-visible, textarea:focus-visible, select:focus-visible {
    outline: lighten($secondary-color, 15%) auto 1px;
}