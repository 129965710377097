.generic {
    padding: 2rem 0 4rem 0;
    &__container {
        margin-bottom: 4rem;
    }
    &__last-updated {
        font-weight: $bold;
    }
    &__title {
        color: $primary-color;
        text-align: center;
    }
    &__heading {
        color: $primary-color;
        font-size: 1.5rem;
    }
    &__subheading {
        color: $primary-color;
        font-size: 1.35rem;
    }
    &__list {
        color: $tertiary-color;
        padding-left: 1.3rem;
    }
    &__list-item {
        color: $tertiary-color;
        margin-bottom: 1.2rem;
    }
    &__list-item p {
        margin: 0.5rem 0;
    }
    &__img-right {
        float: right;
        width: 380px;
        margin-top: 0.6rem;
        margin-left: 3rem;
        margin-bottom: 1rem;
        display: none;
        @include laptop {
            display: block;
        }
    }
}