.testimonials-page {
    padding: 2.5rem 0 4.5rem 0;
    background: $white;
    &__title {
        color: $primary-color;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.9rem;
        margin: 0.26rem 0 0.35rem 0.8rem;
        @include iphone {
            font-size: 1.1rem;
            margin: 0.35rem 0 0.35rem 0.8rem;
        }
    }
    &__heading {
        display: inline-block;
        color: $primary-color;
        letter-spacing: 0.09rem;
        margin: 0.7rem 0 0 0;
    }
    &__content {
        padding: 0 1rem;
        margin: 1.6rem 0 2.7rem 0;
    }
    &__item-content {
        opacity:0.8;
        font-size: 0.95rem;
        padding: 0 1rem;
        margin: 0.8rem 0 1rem 0;
    }
    &__container {
        display: grid;
        grid-gap: 1rem;;
        grid-template-columns: repeat(1, 1fr);
    }
    &__item {
        // border: 1px solid lighten($primary-color, 60%);
        // background: #f7f7f7;
        width: 100%;
        position: relative;
        border: 1px solid #dfe7ef;
        background: #f2f2f2;
        border-radius: 1rem;
        padding: 0;
    }
    &__item.active {
        box-shadow: 0px 0px 12px rgba(2, 38, 79, 0.2);
    }
    &__item.active &__btn::after {
        content: "";
        //background: rgba(2, 38, 79, 0.04);
        //background: rgba(27, 86, 153, 0.04);
        background: rgba(55, 55, 55, 0.02);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1rem;
        border: 1px solid #c4ccd4;
    }
    &__reviewer {
        display: flex;
        flex-wrap: wrap;
        padding: 0.6rem 1rem;
        background: lighten($secondary-color, 30%);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    &__star-rating {
        font-size: 0.9rem;
        margin: 0.15rem 0 0 auto;
        @include iphone {
            font-size: 1rem;
            margin: 0.25rem 0 0 auto;
        }
    }
    &__icon {
        width: 24px;
        @include iphone {
            width: 34px;
        }
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
        height: 100%;
    }
    &__comment {
        font-family: $body-font-family;
        padding: 1.3rem;
        text-align: left;
        margin: 0;
        font-style: italic;
    }
    &__btn {
        display: inline-block;
        background: $primary-color;
        border-radius: 1.8rem;
        color: white;
        padding: 0.65rem 1.5rem;
        margin: 0.3rem 0;
        font-size: 0.9rem;
        transition: background 0.15s ease-in;
    }
    &__btn:hover {
        //background: $secondary-color;
        background: #0a376b;
    }
    &__btn::after {
        content: "";
        //background: rgba(2, 38, 79, 0.05);
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1rem;
    }
}